<template>
  <v-dialog
    v-model="showDialog"
    :width="dialogWidth"
    :fullscreen="isPhone"
    persistent
    overlay-opacity="0.95"
  >
    <v-card tile class="d-flex flex-column noscroll charcoal" flat>
      <BaseCloseDialogHeader
        heading="Finalize Account"
        @cancel="showDialog = false"
      />
      <v-sheet
        :class="textSizeSmall + ' charcoal paper--text  pt-4 pa-2 mt-n2 mx-0'"
      >
        <div class="mb-2">
          <strong>We need your:</strong>
          <span class="progressActive--text ml-1">Weight</span>,
          <span class="progressActive--text ml-1">Height</span>,
          <span class="progressActive--text ml-1">Sex</span> &
          <span class="progressActive--text ml-1">DOB</span>
          <br />
          <span class="silver--text caption">
            We don't need the exact date of birth, just a value that
            approximates your age.</span
          >
        </div>
        <span class="paper--text caption">
          This information is essential for calculating your TDEE (Total Daily
          Energy Expenditure), strength standards, and for the leaderboard.
        </span>
        <v-card-text class="mt-4 pa-0 silver--text caption">
          Please check the 'metric' option below to enter your weight in KGs and
          height in cm (metric system). If you prefer the imperial system, leave
          it unchecked to provide your weight in lbs and height in feet &
          inches.
        </v-card-text>
      </v-sheet>

      <v-card-title class="pa-0 my-n4">
        <v-spacer />
        <v-switch
          dark
          color="progressActive"
          v-model="metric"
          label="Metric?"
          class="pr-2"
        />
      </v-card-title>

      <v-row dense class="px-2">
        <v-col cols="6" md="6">
          <span class="paper--text">Sex</span>
          <v-select
            dark
            v-model="mysex"
            prepend-inner-icon="mdi-gender-male-female"
            :items="sexList"
            color="success"
            solo
            background-color="charcoal lighten-1"
            item-color="success"
            height="60"
          />
        </v-col>
        <v-spacer />
        <v-col cols="6">
          <span class="paper--text" v-if="!metric">Weight (lbs)</span>
          <span class="paper--text" v-else>Weight (Kg)</span>
          <v-text-field
            dark
            :class="isPhone ? 'enlarged-input-small' : 'enlarged-input'"
            solo
            v-if="!metric"
            type="number"
            prepend-inner-icon="mdi-weight"
            v-model="imperialWeight"
            background-color="charcoal lighten-1"
            color="success"
            height="60"
            @input="calculateMetric"
            @focus="$event.target.select()"
          />
          <v-text-field
            dark
            :class="isPhone ? 'enlarged-input-small' : 'enlarged-input'"
            solo
            v-else
            prepend-inner-icon="mdi-weight"
            outlined
            type="number"
            v-model="metricWeight"
            background-color="charcoal lighten-1"
            color="success"
            height="60"
            @input="calculateImperial"
            @focus="$event.target.select()"
          />
        </v-col>
      </v-row>
      <v-row dense class="px-2">
        <template v-if="!metric">
          <v-spacer />
          <v-col cols="6">
            <span class="paper--text">Height (feet)</span>
            <v-text-field
              :class="isPhone ? 'enlarged-input-small' : 'enlarged-input'"
              dark
              solo
              ref="feet"
              v-model="feet"
              type="number"
              prepend-inner-icon="mdi-tape-measure"
              color="success"
              background-color="charcoal lighten-1"
              height="60"
              @input="calculate"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col cols="6">
            <span class="paper--text">Height (inches)</span>
            <v-text-field
              :class="isPhone ? 'enlarged-input-small' : 'enlarged-input'"
              dark
              solo
              ref="inches"
              v-model="inches"
              type="number"
              prepend-inner-icon="mdi-tape-measure"
              color="success"
              background-color="charcoal lighten-1"
              height="60"
              @input="calculate"
              @focus="$event.target.select()"
            />
          </v-col>
        </template>
        <template v-else>
          <v-col cols="6">
            <span class="paper--text">Height(cm)</span>
            <v-text-field
              :class="isPhone ? 'enlarged-input-small' : 'enlarged-input'"
              dark
              solo
              ref="cm"
              v-model="cm"
              type="number"
              prepend-inner-icon="mdi-tape-measure"
              color="success"
              background-color="charcoal lighten-1"
              height="60"
              @input="calculate"
              @focus="$event.target.select()"
            />
          </v-col>
        </template>

        <v-col cols="12">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                dark
                v-model="dob"
                label="Date of Birth"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-on="on"
                offset-y
                min-width="auto"
                background-color="charcoal lighten-1"
                height="60"
              />
            </template>
            <v-date-picker
              color="charcoal"
              year-icon="mdi-chevron-down"
              ref="picker"
              scrollable
              reactive
              v-model="dob"
              :max="new Date().toISOString().substr(0, 10)"
              min="1920-01-01"
              @change="save"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-spacer />
      <v-card-actions class="pa-0">
        <BaseActionButton
          block
          :disabled="
            !metricWeight > 0 ||
              mysex == null ||
              dob == null ||
              (cm == 0 && feet == 0)
          "
          large
          @clickedThis="saveAll()"
          tonal
          dark
          xlarge
          color="progressActive darken-1"
          class=" paper--text text-h5"
        />
      </v-card-actions>
      <NotificationContainer />
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const NotificationContainer = () =>
  import(/* webpackPrefetch: true */ '@/components/NotificationContainer.vue')

export default {
  mixins: [util],
  name: 'WeightPrompt',
  components: { NotificationContainer },
  data: () => ({
    type: 'imperial',
    sexList: ['Male', 'Female'],
    menu: false,
    mysex: null,
    hasWeight: false,
    loaded: false,
    showDialog: false,
    switchnum: 0,
    feet: 0,
    inches: 0,
    heightInches: 0,
    heightCm: 0,
    cm: 0,
    basic: null,
    metric: false,
    metricWeight: 0,
    imperialWeight: 0,
    weightid: 1,
    heightid: 2,
    snackbar: false,
    snackText: '',
    dob: '1998-01-01'
  }),
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  beforeMount() {
    this.mysex = this.sex
    this.showDialog = this.show
  },
  validations: {},
  computed: {
    ...appConfig,
    ...builder,
    inBW() {
      return (
        Math.floor(((this.maxVolume * 1000) / this.basic.weight) * 100) / 100
      )
    }
  },
  watch: {
    show: function(value) {
      this.showDialog = value
    }
  },
  methods: {
    calculateImperial() {
      this.imperialWeight = this.twoDigits(
        this.metricWeight * (1 / this.factor)
      )
    },
    calculateMetric() {
      this.metricWeight = this.twoDigits(this.imperialWeight * this.factor)
    },
    save(dob) {
      this.$refs.menu.save(dob)
    },
    calculate() {
      if (this.metric) {
        this.heightInches = Math.round(this.cm * 0.393701)
        this.heightCm = this.cm
      } else {
        this.heightInches = Number(this.feet) * 12 + Number(this.inches)
        this.heightCm = this.heightInches * 2.54
      }
    },
    async saveAll() {
      await this.saveSexAndGender()
      await this.saveDOB()
      return axios
        .post(this.baseURL + '/measurements', {
          measurementid: this.heightid,
          measured: this.now(),
          imperial: this.heightInches,
          metric: this.heightCm
        })
        .then(response => {
          if (response.status == 200) {
            this.saveWeight()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveWeight() {
      return axios
        .post(this.baseURL + '/measurements', {
          measurementid: this.weightid,
          measured: this.now(),
          imperial: this.imperialWeight,
          metric: this.metricWeight
        })
        .then(response => {
          if (response.status == 200) {
            this.savePreference()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    async saveSexAndGender() {
      return axios
        .post(this.baseURL + '/users/sexgender', {
          sex: this.mysex,
          gender: this.mysex
        })
        .then(response => {
          if (response.status == 200) {
            this.$store.dispatch('session/setSex', this.mysex)
            this.$store.dispatch('session/setGender', this.mysex)
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return 0
          }
        })
    },

    async saveDOB() {
      return axios
        .post(this.baseURL + '/users/dob', {
          dob: this.dob
        })
        .then(response => {
          if (response.status == 200) {
            return 1
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return 0
          }
        })
    },
    savePreference() {
      return axios
        .post(this.baseURL + '/user/preferences', {
          preferences: [{ preferenceid: 3, value: this.metric ? 'Yes' : 'No' }],
          timezone: ''
        })
        .then(response => {
          if (response.status == 200) {
            this.$store.dispatch('session/setUnit', this.metric ? 'Yes' : 'No')
            this.showDialog = false
            this.$emit('done')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
